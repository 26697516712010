import moment from 'moment'
import {
  BCard,
  BButton,
  BRow,
  BCol,
  BBadge,
} from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import SecureLS from 'secure-ls'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BBadge,
  },
  data() {
    return {
      imagesone: '',
      imagesothers: [],
      isLoadingPage: true,
      detailInfo: {},
      countSlide: 0,

      mitra_id: this?.$store?.state?.auth?.userData?.mitras?.id,
    }
  },
  watch: {
    countSlide: {
      handler(newVal, oldVal) {
        this.imagesone = this.imagesothers[newVal]
        this.$refs[`childOtherbox-${oldVal}`][0].classList.remove('borderActive')
        this.$refs[`childOtherbox-${newVal}`][0].classList.toggle('borderActive')
        if (oldVal > newVal) {
          this.$refs.imgcontainer__childBox.scrollLeft -= 100
        } else {
          this.$refs.imgcontainer__childBox.scrollLeft += 100
        }
      },
    },
  },
  computed: {
    tglJoinData() {
      return moment(this.detailInfo.join_date).format('DD MMMM YYYY')
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    handleChangeImge(dt, refKey) {
      this.imagesone = dt
      const [, indexData] = refKey.split('-')
      this.countSlide = parseInt(indexData, 10)
    },
    handleCursor(type) {
      switch (type) {
        case 'left':
          this.countSlide -= 1
          break
        case 'right':
          this.countSlide += 1
          break
        default:
          break
      }
    },
    handleEditData() {
      this.$router.push({
        name: 'profile',
        params: {
          isOnEdit: true,
        },
      })
    },
    fetchData() {
      this.$http.get(`/kompack/warehouse/information/${this.mitra_id}`)
        .then(({ data }) => {
          const dt = data.data
          this.detailInfo = { ...dt }
          this.imagesone = dt.image_warehouse[0].image_url
          this.imagesothers = [...dt.image_warehouse].splice(0, 8).map(x => x.image_url)
          this.$nextTick(() => {
            this.isLoadingPage = false
          })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failed',
              text: 'Galat detail information mitra gudang',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
